/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  input,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { RadioComponent } from '../../core/radio/radio.component';
import { BaseFormFieldComponent } from '../base-form-field.component';
import { FormFieldWrapperComponent } from '../form-field-wrapper.component';

@Component({
  selector: 'do-radio-field',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    `,
  ],
  template: `
    <do-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError()"
      [errors]="errors()"
      [tooltip]="tooltip"
    >
      <do-radio
        [hasError]="shouldShowError()"
        [items]="items"
        [formControl]="formControl"
        [readOnly]="readOnly"
        [inline]="inline()"
      >
      </do-radio>
    </do-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormFieldWrapperComponent, ReactiveFormsModule, RadioComponent],
})
export class RadioFieldComponent extends BaseFormFieldComponent {
  @Input()
  items!: { label: string; value: any }[];

  inline = input(true);
}
